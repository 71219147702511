$(function () {


    // Determine if we are in backend
    var neosInBackend = Boolean($("body.neos-backend").length);

    // Breakpoints
    var breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1012,
        xl: 1440
    };


    /**
     * Main nav extender
     */
    $('#navbarResponsive').on('hide.bs.collapse', function () {
       $(this).closest(".navbar").removeClass("navbar-mobile-shown");
    }).on('show.bs.collapse', function () {
       $(this).closest(".navbar").addClass("navbar-mobile-shown");
    });


    /**
     * Minimizes the padding of an element in dependency of the scroll position.
     * Typically used for sticky header navs.
     */
    $(".scroll-minimizer").each(function () {
        var minimum = 0.1;
        var paddingTop = parseInt($(this).css('padding-top'));
        var paddingBottom = parseInt($(this).css('padding-bottom'));
        var paddingTotal = paddingTop + paddingBottom;
        if (!paddingTotal) return;
        var $minimizerObj = $(this);

        var scrollFunction = function () {
            var scrollRelative = 1 - Math.min($(window).scrollTop() / paddingTotal, 1 - minimum);
            var top = paddingTop * scrollRelative;
            var bottom = paddingBottom * scrollRelative;

            $minimizerObj.css({
                "padding-top": top + "px",
                "padding-bottom": bottom + "px"
            });
        };

        $(window).scroll(scrollFunction);
        scrollFunction();
    });


    /**
     * Scrolls down below the element (self) on click
     */

    $(".scroll-down-self").each(function () {

        if (neosInBackend) {
            return;
        }

        $(this).css("cursor", "pointer");
        $(this).on("click", function (event) {
            event.preventDefault();

            var offset = -65;
            var dataOffset = $(this).data("scroll-down-offset");
            if (dataOffset !== undefined) {
                offset = parseFloat(dataOffset);
            }

            $("html, body").animate({
                scrollTop: $(this).position().top + $(this).outerHeight(true) - offset
            }, 500);
        });
    });


    $(".fade-scroll").each(function () {

        var minVisibility = 0.0;
        var maxVisibility = 1.0;
        if ($(this).data("min")) {
            minVisibility = parseFloat($(this).data("min"));
        }
        if ($(this).data("max")) {
            minVisibility = parseFloat($(this).data("max"));
        }

        if (neosInBackend) {
            return;
        }

        var $container = $(this);
        var direction = $container.data('direction');
        if (!direction) {
            direction = 'out';
        }

        $(window).on("scroll resize", function () {
            calculateVisibility();
        });
        calculateVisibility();

        function calculateVisibility() {

            if ($(window).width() < breakpoints.md) {
                $container.css('opacity', 1);
                return;
            }

            var scroll = $container.offset().top - $(document).scrollTop();
            var height = Math.min($container.height(), $(window).height() / 2);
            var offset = 50;

            var startFade, visibility;

            if (direction === 'out') {
                startFade = Math.max(-scroll + offset, 0);
                visibility = Math.min(1 - startFade / height, 1);
            } else {
                startFade = Math.max($(window).height() - scroll - offset, 0);
                visibility = Math.min(startFade / height, 1);
            }

            // Clamp low visibility
            visibility = (visibility * (maxVisibility - minVisibility)) + minVisibility;
            var grayscale = (1 - visibility) * 100;

            $container.css('opacity', visibility);
            $container.css('filter', 'grayscale(' + grayscale + '%)');
        }

    });


    function initSwiper($element) {

        $element.each(function () {

            var initialSlide = 0;
            var $slides = $(this).find(".swiper-slide");

            $slides.each(function () {

                var categories = $(this).data("categories");
                var hash = window.location.hash.substring(1);

                if (!categories || !hash) {
                    return;
                }

                if (categories.indexOf(hash) !== -1) {
                    initialSlide = $slides.index($(this));
                    return false;
                }
            });

            var autoplay = false;
            if ($(this).data("autoplay")) {
                autoplay = {
                    delay: 6500
                };
            }

            new Swiper($(this)[0], {
                direction: 'horizontal',
                loop: true,
                initialSlide: initialSlide,
                autoHeight: true,
                speed: 400,
                spaceBetween: 2,
                keyboard: {
                    enabled: true,
                },
                autoplay: autoplay,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

        });
    }

    initSwiper($('.swiper-container'));


    /**
     * Category menu accordion
     */
    $(".category-menu").each(function () {

        var $categories = $(this).find("li.category");

        $categories.each(function () {

            var $category = $(this);
            var $projects = $(this).find("ul.projects");

            $category.data("projects", $projects);

            // Hide initially
            $projects.hide();
            $category.data("hidden", true);
            $category.addClass("menu-hidden");

            var $title = $(this).find(".category-title");

            // Cursor pointer
            $title.css("cursor", "pointer");

            // Append click event
            $title.on("click", function () {

                $categories.each(function () {

                    // Check if current and if not hidden
                    var $currentCategory = $(this);
                    if ($currentCategory[0] === $category[0] && $currentCategory.data("hidden")) {

                        // Show
                        $currentCategory.removeClass("menu-hidden");
                        $currentCategory.data("hidden", false);
                        $(this).data("projects").slideDown(250);

                    } else {

                        // Hide
                        $currentCategory.addClass("menu-hidden");
                        $currentCategory.data("hidden", true);
                        $(this).data("projects").slideUp(250);
                    }

                });
            });
        });

    });


    //
    // document.addEventListener('Neos.NodeSelected', function (event) {
    // 	var element = event.detail.element;
    // 	if (!element) return;
    //
    // 	// Only act if it is a swiper-slider
    // 	if ($(element).hasClass('swiper-slide')) {
    //
    // 		var $swiperContainer = $(element).closest('.swiper-container');
    //
    // 		// Find out which index the selected element is
    // 		var index = $swiperContainer.find('.swiper-slide').index(element);
    //
    // 		// Slide to selected slide
    // 		$swiperContainer[0].swiper.update();
    // 		$swiperContainer[0].swiper.slideTo(index);
    //
    // 	}
    //
    // 	// console.log('Neos.NodeSelected');
    //
    // 	// var node = event.detail.node;
    // 	// if (node.get('nodeType') === 'Nxb.KtpArchitektenDe:SwiperElement') {
    // 	// 	console.log(node.get('identifier'));
    // 	// 	console.log(node.get('properties'));
    // 	// }
    // }, false);
    //
    //
    // document.addEventListener('Neos.NodeCreated', function (event) {
    // 	var element = event.detail.element;
    // 	if (!element) return;
    //
    //
    // 	// It is a swiper-slide
    // 	if ($(element).hasClass('swiper-slide')) {
    // 		// Update slider
    // 		var $swiperContainer = $(element).closest('.swiper-container');
    // 		$swiperContainer[0].swiper.update();
    // 	}
    //
    // 	// It is a swiper-container
    // 	if ($(element).hasClass('swiper-container')) {
    // 		initSwiper($(element));
    // 	}
    //
    //
    // 	// console.log('Neos.NodeCreated');
    // 	// console.log(element);
    // 	// console.log(element.attributes);
    //
    // }, false);
    //
    // document.addEventListener('Neos.NodeRemoved', function (event) {
    // 	var element = event.detail.element;
    // 	if (!element) return;
    //
    //
    // 	// It is a swiper-slide
    // 	if ($(element).hasClass('swiper-slide')) {
    // 		// Update slider
    // 		var $swiperContainer = $(element).closest('.swiper-container');
    // 		$swiperContainer[0].swiper.update();
    // 	}
    //
    //
    // 	// console.log('Neos.NodeRemoved');
    // 	// console.log(element);
    // 	// console.log(element.attributes);
    //
    // }, false);


    // $(".container-full").each(function () {
    //
    // 	var headerHeight = 80;
    // 	var $container = $(this);
    //
    // 	var resizeFunction = function () {
    // 		$container.height($(window).height() - headerHeight);
    // 	};
    //
    // 	$(window).resize(resizeFunction);
    // 	resizeFunction();
    //
    // });
    //
    //
    // $(".container-full-not-cropped").each(function () {
    //
    // 	var headerHeight = 120;
    // 	var $container = $(this);
    //
    // 	var resizeFunction = function () {
    // 		if ($(window).width() < 576) {
    // 			$container.css("height", "auto");
    // 			return;
    // 		}
    //
    // 		$container.height($(window).height() - headerHeight);
    //
    // 	};
    //
    // 	$(window).resize(resizeFunction);
    // 	resizeFunction();
    //
    // });
    //

});
